import LazyLoad from "vanilla-lazyload";

'use strict'

const lazyload = {
		init: function () {
				var lazyLoadInstance = new LazyLoad({
						elements_selector: ".lazy"
				});
		}
}

export default lazyload.init()
