'use strict'

// Polyfill for IE11 (https://github.com/w3c/IntersectionObserver/tree/master/polyfill)
require('intersection-observer');

const videoObserver = {
		init: function () {
				let observables = document.querySelectorAll(".video-observer__video--observe")

				if (observables.length === 0) return

				let options = {
						root: null,
						rootMargin: '0px',
						threshold: 1.0
				}

				let observer = new IntersectionObserver(this.handleIntersections, options);

				observables.forEach(observable => {
						this.mediaSource(observable)
						observer.observe(observable)
				})
		},

		handleIntersections: function (entries) {
				entries.forEach(entry => {
						let element = entry.target;

						if (entry.intersectionRatio === 1) {
								let playPromise = element.play();

								if (playPromise !== undefined) {
										playPromise.then(_ => {
												element.play()
										})
								}
						}
						else {
								element.pause()
						}
				})
		},

		mediaSource: function (target) {
				const mediaSource = window.matchMedia("(max-width: 479px)").matches
						? target.dataset.mobileSrc === ""
								? target.dataset.desktopSrc
								: target.dataset.mobileSrc
						: target.dataset.desktopSrc

				target.src = mediaSource
				target.load()
		}
}

export default videoObserver.init()
