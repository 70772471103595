'use strict'

window.addEventListener('load', function () {
		const ctaWindows = document.querySelectorAll(".cta-window__wrapper")
		const linkButtons = document.querySelectorAll(".cta-link__button")
		let ctaHeight = 0;

		linkButtons.forEach(linkButton =>
				ctaHeight = ctaHeight + linkButton.clientWidth
		)

		if (ctaWindows != undefined) {
				ctaWindows.forEach(ctaWindow =>
						ctaWindow.style.minWidth = ctaHeight + "px"
				)
		}
})
